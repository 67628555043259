<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "zhongDiSZ",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "功能说明",
                        text:[
                            '#此功能为家园-青山隐玩法中自动种地的功能',
                            '#选择生活-山庄种地,设置种子(半小时红椒和一小时苋菜),设置土地(1-8块地和1-16块地)',
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            '#进入青山隐场景,在商人处购买上面选择的种子,买多少种多少!',
                            '#角色移动到农田"一号土地",图1位置,注意图中说明!',
                            '#使用快捷键开始助手,算好时间,助手会一直种地不会自动停止! 请勿在农田外开始,因为栅栏会卡住角色',
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
